<template>
  <div class="orderQuery">
    <div class="queryTitle">订单查询</div>
    <!-- 订单展示 -->
    <!-- 标题 -->

    <v-app id="inspire" v-if="isLogin">
      <v-simple-table>
        <template>
          <thead>
            <tr>
              <th class="text-left" style="width: 1.6rem"></th>
              <th class="text-left" style="width: 6.2rem">订单编号</th>
              <th class="text-left" style="width: 7.4rem">订单日期</th>
              <th class="text-left" style="width: 7.6rem">交货日期</th>
              <th class="text-left" style="width: 7.8rem">来纱日期</th>
              <th class="text-left" style="width: 4.2rem">数量</th>
              <th class="text-left" style="width: 4.2rem">重量</th>
              <th class="text-left" style="width: 5.2rem">创建人</th>
              <th class="text-left" style="width: 6rem">确认日期</th>
              <th class="text-left" style="width: 5.2rem">确认人</th>
              <th class="text-left" style="width: 6.2rem">答复日期</th>
              <th class="text-left" style="width: 6.2rem">确认状态</th>
              <th class="text-left" style="width: 4rem"></th>
            </tr>
          </thead>
        </template>
      </v-simple-table>

      <v-expansion-panels focusable popout>
        <v-expansion-panel
          v-for="(item, i) in orderList"
          :key="i"
          @click="getDetailInfo(item)"
        >
          <v-expansion-panel-header>
            <div
              style="
                color: #d3002f;
                font-weight: bold;
                width: 2rem;
                font-size: 1rem;
              "
            >
              {{ i + 1 }}
            </div>
            <div style="display: none">
              {{ item.id }}
            </div>
            <div class="desserts" style="width: 6rem">
              {{ item.code }}
            </div>

            <div class="desserts" style="width: 7.8rem">
              {{ item.billdate }}
            </div>
            <div class="desserts" style="width: 8.6rem; color: red">
              {{ item.cust_shipDate }}
            </div>
            <div class="desserts" style="width: 8.6rem">
              {{ item.yarn_date }}
            </div>
            <div class="desserts" style="width: 5rem">
              {{ item.qty2 }}&nbsp;{{ item.unit2 }}
            </div>
            <div class="desserts" style="width: 5rem">
              {{ item.qty }}&nbsp;{{ item.unit }}
            </div>
            <div class="desserts" style="width: 3.4rem">
              {{ item.record_by }}
            </div>
            <div class="desserts" style="width: 8.6rem">
              {{ item.cfm_date }}
            </div>
            <div class="desserts" style="width: 3.4rem">
              {{ item.cfm_by }}
            </div>
            <div class="desserts" style="width: 8.6rem; color: red">
              {{ item.reply_shipdate }}
            </div>
            <div class="desserts" style="width: 2.8rem">
              <el-tooltip
                :content="item.cfm == 1 ? '已确认' : '未确认'"
                placement="top"
                effect="light"
              >
                <i
                  :class="
                    item.cfm == 1
                      ? 'fa fa-check-circle-o fa-lg'
                      : 'fa fa-circle-o fa-lg'
                  "
                  aria-hidden="true"
                  style="vertical-align: middle"
                ></i>
              </el-tooltip>
            </div>
            <div style="width: 6rem">
              <el-tooltip content="查看订单" placement="top" effect="light">
                <v-icon
                  small
                  class="mr-2"
                  color="#007bff"
                  @click.stop="goEdit(item.id, item.cfm)"
                >
                  mdi-pencil
                </v-icon>
              </el-tooltip>
              <el-tooltip content="删除订单" placement="top" effect="light">
                <v-icon
                  small
                  :disabled="item.cfm == 1 || cust_id == '261'"
                  color="#f44336"
                  @click.stop="deleteData(item, i)"
                >
                  mdi-delete
                </v-icon>
              </el-tooltip>
              <el-tooltip content="确认订单" placement="top" effect="light">
                <v-icon
                  small
                  :disabled="item.cfm == 1 || cust_id == '261'"
                  color="#43a047"
                  @click.stop="ensureData(item, i)"
                >
                  mdi-check-circle
                </v-icon>
              </el-tooltip>
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content>
            <table class="table table-bordered">
              <thead style="background-color: #f3f3f3">
                <tr>
                  <th scope="col">客单号</th>
                  <th scope="col">客布号</th>
                  <th scope="col">产品名称</th>
                  <th scope="col">款号</th>
                  <th scope="col">颜色</th>
                  <th scope="col">尺寸</th>
                  <th scope="col">数量</th>
                  <th scope="col">重量</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, i) in orderChildList" :key="i">
                  <td>{{ item.cust_po }}</td>
                  <td>{{ item.cust_no }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.style_code }}</td>
                  <td>{{ item.color }}</td>
                  <td>{{ item.sizes }}</td>
                  <td>{{ item.qty2 }}&nbsp;{{ item.unit2 }}</td>
                  <td>{{ item.qty }}&nbsp;{{ item.unit }}</td>
                </tr>
              </tbody>
            </table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- 确认区域 -->
      <v-dialog v-model="dialogSave" max-width="400px">
        <v-card>
          <v-card-title class="headline"
            >订单一旦确认，不可修改，是否继续？</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogSave = false"
              >取消</v-btn
            >
            <v-btn color="blue darken-1" text @click="ensureConfirm"
              >确定</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- 删除区域 -->
      <v-dialog v-model="dialogDel" max-width="350px">
        <v-card>
          <v-card-title class="headline">确定删除此订单吗?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogDel = false"
              >取消</v-btn
            >
            <v-btn color="blue darken-1" text @click="deleteConfirm"
              >确定</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import "../../assets/css/orderquery.less";
import "../../assets/css/adminhome.less";
import "../../assets/css/dashboard.less";

export default {
  data() {
    return {
      // 用户名
      username: "",
      cust_id: "",
      cust_name: "",
      // 检测是否登录
      isLogin: false,
      // 订单列表数据
      orderList: [],
      // 明细表数据
      orderChildList: [],
      // 删除弹框
      dialogDel: false,
      // 确认弹框
      dialogSave: false,
      // 弹框的相关数据项
      dialogItem: "",
      // 弹框数据的序号
      dialogId: "",
      // 获取当前时间
      currentDate: "",
      url: "http://beian.miit.gov.cn/",
    };
  },

  created() {
    this.getUserInfo();

    if (sessionStorage.getItem("user")) {
      this.isLogin = true;
    }

    this.getOrderInfo();
  },

  mounted() {
    this.currentDate = this.todayDate(new Date());
    this.timer = setInterval(() => {
      this.todayDate(new Date());
    }, 500);
  },

  methods: {
    goBean() {
      var r = confirm("您将要访问：http://beian.miit.gov.cn/，是否继续？");
      if (r == true) {
        window.location.href = this.url;
      }
    },
    // 当前日期
    todayDate(data) {
      var date = new Date();
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      var d = date.getDate();
      var h = date.getHours();
      var min = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      d = d < 10 ? "0" + d : d;
      h = h < 10 ? "0" + h : h;
      min = min < 10 ? "0" + min : min;
      return y + "-" + m + "-" + d + " " + h + ":" + min;
    },

    // 确定数据弹框
    ensureData(item, i) {
      this.dialogSave = true;
      this.dialogItem = item;
      this.dialogId = i;
    },

    // 确定保存此数据
    ensureConfirm() {
      this.axios({
        methods: "GET",
        url: "http://wx.hengfeng-zl.cn/dingtalk/weborders.ashx",
        params: {
          ctype: "confirmOrder",
          orderId: this.dialogItem.id,
          cfm_by: this.username,
        },
      })
        .then((res) => {
          this.dialogSave = false;
          this.dialogItem.cfm = 1;
          this.dialogItem.cfm_by = this.username;
          this.dialogItem.cfm_date = this.currentDate;
        })
        .catch((err) => {});
    },

    // 删除数据弹框
    deleteData(item, i) {
      this.dialogDel = true;
      this.dialogItem = item;
      this.dialogId = i;
    },

    // 确定删除此条数据
    deleteConfirm() {
      this.axios({
        methods: "GET",
        url: "http://wx.hengfeng-zl.cn/dingtalk/weborders.ashx",
        params: {
          ctype: "DelOrder",
          orderId: this.dialogItem.id,
        },
      })
        .then((res) => {
          this.dialogDel = false;
          this.orderList.splice(this.dialogId, 1);
          if (res.data.row == 1) {
            this.$message({
              showClose: true,
              message: "订单删除成功",
              type: "success",

              // message: h('p', null, [
              //   h('span', {style: 'font-size: 16px; color: red'}, '订单删除成功')
              // ])
            });
          }
        })
        .catch((err) => {});
    },

    // 跳转页面
    goPage(name) {
      this.$router.push({ name });
    },

    // 跳转至对应的编辑页面
    goEdit(id, cfm) {
      this.$router.push({ name: "EditOrder", query: { id, cfm } });
    },

    // 获取用户信息
    getUserInfo() {
      this.username = sessionStorage.getItem("user");
      this.cust_id = sessionStorage.getItem("cust_id");
      this.cust_name = sessionStorage.getItem("cust_name");
    },

    // 退出登录
    logout() {
      if (this.cust_id == "261") {
        this.$router.push({ name: "CompanyLogin" });
      } else {
        this.$router.push({ name: "Login" });
      }
      sessionStorage.clear();
    },

    // 获取订单列表信息
    getOrderInfo() {
      this.axios({
        methods: "GET",
        url: "http://wx.hengfeng-zl.cn/dingtalk/weborders.ashx",
        params: {
          ctype: "getOrderList",
          cust_id: this.cust_id,
        },
      })
        .then((res) => {
          this.orderList = res.data.data;
          for (let i = 0; i < this.orderList.length; i++) {
            if (this.orderList[i].cust_shipDate != null) {
              this.orderList[i].cust_shipDate = this.orderList[
                i
              ].cust_shipDate.substring(0, 16);
              for (let i = 0; i < this.orderList.length; i++) {
                if (this.orderList[i].yarn_date != null) {
                  this.orderList[i].yarn_date = this.orderList[
                    i
                  ].yarn_date.substring(0, 16);
                  for (let i = 0; i < this.orderList.length; i++) {
                    if (this.orderList[i].cfm_date != null) {
                      this.orderList[i].cfm_date = this.orderList[
                        i
                      ].cfm_date.substring(0, 16);
                      for (let i = 0; i < this.orderList.length; i++) {
                        if (this.orderList[i].reply_shipdate != null) {
                          this.orderList[i].reply_shipdate = this.orderList[
                            i
                          ].reply_shipdate.substring(0, 16);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        })
        .catch((err) => {});
    },

    // 获取详细信息
    getDetailInfo(item) {
      this.axios({
        methods: "GET",
        url: "http://wx.hengfeng-zl.cn/dingtalk/weborders.ashx",
        params: {
          ctype: "getOrderInfo",
          orderId: item.id,
        },
      })
        .then((res) => {
          this.orderChildList = res.data.orderD;
        })
        .catch((err) => {});
    },

    handleOpen(key, keyPath) {},

    handleClose(key, keyPath) {},
  },

  destroyed() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-expansion-panel-content__wrap {
  padding: 20px 50px 10px;
  background-color: #f6f7fb;
}
::v-deep .v-expansion-panel {
  max-width: 100%;
}
::v-deep
  .theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr
  > th {
  font-size: 0.9rem;
  color: black;
  text-align: center !important;
}
::v-deep .v-application--is-ltr .v-expansion-panel-header {
  padding: 5px 5px;
  text-align: center;
}
::v-deep .v-icon.v-icon.v-icon--link {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
::v-deep .v-dialog > .v-card > .v-card__title {
  font-size: 1rem !important;
  margin-bottom: 20px;
}
::v-deep .mdi-delete::before {
  margin: 0 16px 0 10px;
}
::v-deep .v-item-group {
  margin-bottom: 60px;
  background-color: #f3f3f3;
}
::v-deep .theme--light.v-data-table {
  position: sticky;
  top: 98px;
  z-index: 99;
  background-color: #e5e5e5;
}
::v-deep .v-application {
  margin: 6px 6px 10px;
  background-color: #f3f3f3;
}
::v-deep .v-expansion-panel:nth-child(2n) {
  background-color: #f6f6f6;
}
::v-deep .v-application .blue--text.text--darken-1 {
  color: #d3002f !important;
  caret-color: #d3002f !important;
}
</style>